import { useInfiniteQuery } from "@tanstack/react-query";
import { createRef } from "react";

import { apiAlertClient } from ".";
import { IAssociatedCrime, IPoliceService } from "../../types";
import { dateToISOString } from "../utils";

export function useAlertsInfiniteQuery(
  itemsPerPage: number | null,
  associatedCrime: IAssociatedCrime | null,
  bulletinType: string | null,
  policeService: IPoliceService | null,
  incidentStartDate: string | null,
  incidentEndDate: string | null,
  issuedStartDate: string | null,
  issuedEndDate: string | null,
  searchText: string | null,
  is_draft: boolean | null,
  creator_user_id: string | null,
  similarTo: number | null,
  bounds: string | null,
  creator_org_id: string | null,
) {
  const bulletinRefs: { [key: string]: React.RefObject<any> } = {};

  return useInfiniteQuery(
    [
      "alerts",
      associatedCrime,
      bulletinType,
      policeService,
      creator_org_id,
      incidentStartDate,
      incidentEndDate,
      issuedStartDate,
      issuedEndDate,
      searchText,
      is_draft,
      creator_user_id,
      similarTo,
      bounds,
    ],
    async ({ pageParam = 0 }) => {
      const res = await apiAlertClient.getBulletins(
        pageParam,
        itemsPerPage,
        associatedCrime?.crime,
        bulletinType,
        policeService as IPoliceService,
        creator_org_id ? creator_org_id : null,
        incidentStartDate ? dateToISOString(incidentStartDate) : null,
        incidentEndDate ? dateToISOString(incidentEndDate) : null,
        issuedStartDate ? dateToISOString(issuedStartDate) : null,
        issuedEndDate ? dateToISOString(issuedEndDate) : null,
        searchText,
        is_draft ? is_draft : false,
        creator_user_id ? creator_user_id : null,
        similarTo ? similarTo : null,
        bounds ? bounds : null,
      );
      return res;
    },
    {
      getNextPageParam: (lastPage, pages) => {
        if (
          lastPage?.items.length === 0 ||
          (itemsPerPage && lastPage?.items.length < itemsPerPage)
        ) {
          return undefined;
        }
        if (itemsPerPage) {
          return pages.length * itemsPerPage;
        }
        return undefined;
      },
      onSuccess: (data) => {
        data.pages.forEach((page) =>
          page.items.forEach((bulletin) => {
            bulletinRefs[bulletin.id] = createRef();
          }),
        );
      },
      // TODO: Figure out single record addition / mutation and restore cache
      staleTime: 5 * 60 * 1000,
      cacheTime: 5 * 60 * 1000,
    },
  );
}
