import { List, Map, Search, Tune, ViewModule } from "@mui/icons-material";
import Close from "@mui/icons-material/Close";
import { Button, Divider, IconButton, InputBase, Paper } from "@mui/material";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

interface SearchBarProps {
  getMoreItems: () => void;
  onSearch: () => void;
  onClearSearch: () => void;
  searchView: string;
  setSearchView: (value: "card" | "list" | "map") => void;
  defaultView: "card" | "list" | "map";
  searchText: string;
  setSearchText: React.Dispatch<React.SetStateAction<string>>;
  currentItems: any[];
  setCurrentItems: React.Dispatch<React.SetStateAction<any[]>>;
  filtersVisible: boolean;
  setFiltersVisible: React.Dispatch<React.SetStateAction<boolean>>;
  totalAlerts: number;
  loading: boolean;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  searchType: "alert" | "report" | "registry";
  filterChips?: any;
  filtersRef: React.MutableRefObject<HTMLInputElement | null>;
  activeViews: ("card" | "list" | "map")[];
}

const iconMapping = {
  card: <ViewModule style={{ marginRight: "4px" }} />,
  list: <List style={{ marginRight: "4px" }} />,
  map: <Map style={{ marginRight: "4px" }} />,
};

export const getSearchResultMessage = (
  loading: boolean,
  searchText: string,
  currentItemsLength: number,
  totalAlerts: number,
  t: (key: string, options?: any) => string,
): string => {
  if (loading) {
    return searchText
      ? t("loadingSearchResults")
      : currentItemsLength < totalAlerts
        ? t("loadingMoreItems", {
            currentItems: currentItemsLength,
            totalItems: totalAlerts,
          })
        : t("preparingResults");
  }

  if (searchText && totalAlerts === 0) {
    return t("noResultsFound");
  }

  if (currentItemsLength) {
    return searchText
      ? t("displayResults", { totalAlerts, searchText })
      : t("displayItems", {
          currentItems: currentItemsLength,
          totalItems: totalAlerts,
        });
  }

  if (currentItemsLength === 0 && totalAlerts === 0 && searchText === "") {
    return t("noResultsFound");
  }

  return "";
};

const SearchBar = ({
  getMoreItems,
  onSearch,
  onClearSearch,
  searchView,
  setSearchView,
  defaultView,
  searchText,
  setSearchText,
  currentItems,
  setCurrentItems,
  filtersVisible,
  setFiltersVisible,
  totalAlerts,
  loading,
  setLoading,
  searchType,
  filterChips,
  filtersRef,
  activeViews,
}: SearchBarProps) => {
  const [newSearchText, setNewSearchText] = useState(searchText || "");
  const { t } = useTranslation("bulletin/searchbar");
  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewSearchText(event.target.value);
  };

  const handleSearchEnter = () => {
    if (newSearchText !== "") {
      setSearchText(newSearchText);
      setLoading(true);
      setCurrentItems([]);
      onSearch();
    }
  };

  const handleClearSearch = () => {
    setSearchText("");
    setNewSearchText("");
    onClearSearch();
  };

  useEffect(() => {
    if (newSearchText === "" && !loading) {
      handleClearSearch();
    }
  }, [newSearchText]);

  const searchResultMessage = useMemo(() => {
    return getSearchResultMessage(
      loading,
      searchText,
      currentItems.length,
      totalAlerts,
      t,
    );
  }, [searchText, currentItems.length, totalAlerts, loading, t]);

  return (
    <div className="search-bar">
      <div
        ref={filtersRef}
        style={{ maxWidth: "650px" }}
        className="search-bar-container d-flex flex-row"
      >
        <Paper
          component="form"
          elevation={4}
          sx={{
            p: "2px 4px",
            display: "flex",
            alignItems: "center",
            width: "100%",
            borderRadius: "24px",
            zIndex: 4,
            top: "1",
            background: "#f1f3f4",
          }}
        >
          <IconButton
            type="button"
            sx={{ p: "10px", color: "#5F6368" }}
            aria-label="search"
          >
            <Search />
          </IconButton>

          <InputBase
            sx={{
              ml: 1,
              flex: 1,
              fontSize: "16px",
              color: "#202124",
            }}
            placeholder={t("search")}
            value={newSearchText}
            onChange={handleSearchChange}
            onKeyDown={(event) => {
              if (event.key === "Enter") {
                event.preventDefault();
                handleSearchEnter();
              }
            }}
          />

          <IconButton
            type="button"
            aria-label="clear"
            sx={{ p: "10px", color: "#5F6368" }}
            onClick={() => handleClearSearch()}
          >
            <Close />
          </IconButton>
        </Paper>
        <Button
          className="ml-3"
          variant="outlined"
          onClick={() => setFiltersVisible(true)}
        >
          <Tune />
          {t("filters")}
        </Button>
      </div>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          gap: "8px",
          marginTop: "8px",
        }}
      >
        {filterChips}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "self-start",
          borderBottom: "1px solid #e0e0e0",
          padding: "10px 0",
          fontSize: "14px",
          color: "#70757a",
        }}
      >
        {activeViews && activeViews.length > 1 && (
          <Button
            sx={{
              marginRight: "24px",
              textTransform: "none",
              fontWeight: searchView === defaultView ? "bold" : "normal",
              color: searchView === defaultView ? "#1a73e8" : "#70757a",
              borderBottom:
                searchView === defaultView ? "2px solid #1a73e8" : "none",
              paddingBottom: "6px",
              "&:hover": {
                borderBottom: "2px solid #1a73e8",
                paddingBottom: "6px",
              },
            }}
            onClick={() => setSearchView(defaultView)}
          >
            {iconMapping[defaultView]}
            {t("defaultView")}
          </Button>
        )}

        {activeViews && activeViews.length > 1 && defaultView !== "card" && (
          <Button
            sx={{
              marginRight: "24px",
              textTransform: "none",
              fontWeight: searchView === "card" ? "bold" : "normal",
              color: searchView === "card" ? "#1a73e8" : "#70757a",
              borderBottom:
                searchView === "card" ? "2px solid #1a73e8" : "none",
              paddingBottom: "6px",
              "&:hover": {
                borderBottom: "2px solid #1a73e8",
                paddingBottom: "6px",
              },
            }}
            onClick={() => setSearchView("card")}
          >
            <ViewModule style={{ marginRight: "4px" }} />
            {t("cardView")}
          </Button>
        )}

        {activeViews && activeViews.length > 1 && defaultView !== "map" && (
          <Button
            sx={{
              marginRight: "24px",
              textTransform: "none",
              fontWeight: searchView === "map" ? "bold" : "normal",
              color: searchView === "map" ? "#1a73e8" : "#70757a",
              borderBottom: searchView === "map" ? "2px solid #1a73e8" : "none",
              paddingBottom: "6px",
              "&:hover": {
                borderBottom: "2px solid #1a73e8",
                paddingBottom: "6px",
              },
            }}
            onClick={() => setSearchView("map")}
          >
            <Map style={{ marginRight: "4px" }} />
            {t("mapView")}
          </Button>
        )}
        {activeViews && activeViews.length > 1 && defaultView !== "list" && (
          <Button
            sx={{
              marginRight: "24px",
              textTransform: "none",
              fontWeight: searchView === "list" ? "bold" : "normal",
              color: searchView === "list" ? "#1a73e8" : "#70757a",
              borderBottom:
                searchView === "list" ? "2px solid #1a73e8" : "none",
              paddingBottom: "6px",
              "&:hover": {
                borderBottom: "2px solid #1a73e8",
                paddingBottom: "6px",
              },
            }}
            onClick={() => setSearchView("list")}
          >
            <List style={{ marginRight: "4px" }} />
            {t("listView")}
          </Button>
        )}
      </div>

      <div
        style={{
          fontSize: "14px",
          color: "#70757a",
          marginBottom: "8px",
        }}
      >
        {searchResultMessage}
      </div>
    </div>
  );
};

export default SearchBar;
